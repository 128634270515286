<template>
  <div>
    <div class="searchBox">
      <div class="searchLeft">
        <div>
          <el-input
              size="medium"
              clearable
              v-model="searchForm.name"
              placeholder="请输入标签名称"
              @keyup.enter.native="getTableList"
          ></el-input>
        </div>
        <el-button size="medium" type="primary" icon="el-icon-search" @click="getTableList">搜索</el-button>
      </div>
      <div class="searchRight">
        <el-button
          size="medium"
          type="warning"
          icon="el-icon-plus"
          @click="addFunc"
          >新增</el-button
        >
      </div>
    </div>
    <el-table border :data="tableData" height="76vh" style="width: 99%">
      <el-table-column prop="id" label="id"></el-table-column>
      <el-table-column prop="tagName" label="标签名称"></el-table-column>
      <el-table-column label="图标">
        <template slot-scope="scope">
          <img :width="100" :src="scope.row.icon" class="icon" />
        </template>
      </el-table-column>
      <el-table-column prop="taskNum" label="任务数量"></el-table-column>
      <!-- <el-table-column prop="whiteListCount" label="推荐房间">
      </el-table-column> -->
      <el-table-column prop="category" label="标签类型">
        <template slot-scope="scope">
          <span>{{taskTagCategory[scope.row.category]}}</span>
        </template>
      </el-table-column>
      <!-- <el-table-column prop="feedStatusTxt" label="首页推荐"></el-table-column> -->
      <el-table-column label="操作" min-width="160">
        <template slot-scope="scope">
          <el-button size="small" type="primary" @click="toEdit(scope.row)"
            >编辑</el-button
          >
          <el-button
            size="small"
            type="danger" 
            @click="delFunc(scope.row)"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <pagination :totalNum="totalNum" :currentPage.sync="searchForm.pageNo" :pageSize.sync="searchForm.pageSize" @paginationChange="getTableList"></pagination>
    <el-dialog :title="`${ruleForm.id?'编辑':'添加'}标签`" :visible.sync="addVisible" width="650px">
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="130px"
        class="demo-ruleForm"
      >
        <el-form-item label="标签名称" prop="tagName" :rules="rules.blurRule">
          <el-input
            placeholder="请输入内容"
            v-model="ruleForm.tagName"
          ></el-input>
        </el-form-item>
        <el-form-item label="标签类型" prop="category" :rules="rules.changeRule">
          <el-select
            clearable
            v-model="ruleForm.category"
            placeholder="类型"
          >
            <el-option
              v-for="(item,key) in taskTagCategory"
              :key="key"
              :label="item"
              :value="key">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="标签图标" prop="icon" :rules="rules.typeIcon">
          <uploadImg @change="uploadImgChange" :imageFile="[{url:ruleForm.icon}]"></uploadImg>
        </el-form-item>
        <el-form-item label="标签背景颜色" prop="color" :rules="rules.changeRule">
          <el-color-picker v-model="ruleForm.color"></el-color-picker>
        </el-form-item>
        <el-form-item label="标签字体颜色" prop="fontColor" :rules="rules.changeRule">
          <el-color-picker v-model="ruleForm.fontColor"></el-color-picker>
        </el-form-item>
        <el-form-item label="推荐白名单">
          <el-input
            type="textarea"
            :rows="5"
            placeholder="填写房间ID，任务发布后任务会直接下发给白名单内里的房间。下发任务不受任务限制影响。"
            v-model="ruleForm.whiteList">
          </el-input>
        </el-form-item>
      </el-form>
      
      
      <span slot="footer" class="dialog-footer">
        <el-button @click="addVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitFunc">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import uploadImg from '@/components/uploadImg'
import pagination from '@/components/pagination'
export default {
  data() {
    return {
      totalNum: 0,
      addVisible: false,
      ruleForm: {},
      searchForm: {
        pageNo: 1,
        pageSize: 10
      },
      taskTagCategory: {
        '0':'一级分类',
        '1':'二级分类',
        '2':'三级分类',
      },
      tableData: [],
      dialogVisible: false,
      rules: {
        blurRule: [
          { required: true, message: "请输入", trigger: "blur" },
        ],
        changeRule: [
          { required: true, message: "请选择", trigger: "change" },
        ],
        typeIcon: [{ required: true, message: "请上传图片", trigger: "change" }],
      },
    };
  },
  components:{uploadImg,pagination},
  created() {
    this.getTableList();
  },
  methods: {
    addFunc(){
      this.ruleForm = {};
      this.ruleForm.taskNum = 0;
      this.addVisible = true;
    },
    uploadImgChange(val){
      if(val&&val.length>0) {
        this.ruleForm.icon=val[0].url
      }
    },
    toEdit(data) {
      //编辑
      this.ruleForm = {...data}
      this.ruleForm.color = this.formatColorSixteen(this.ruleForm.color)
      this.ruleForm.fontColor = this.formatColorSixteen(this.ruleForm.fontColor)
      this.addVisible = true;
    },
    async submitFunc() {
      let ruleForm = {...this.ruleForm}
      ruleForm.color = this.formatColorTen(ruleForm.color);
      ruleForm.fontColor = this.formatColorTen(ruleForm.fontColor);
      this.$refs.ruleForm.validate(async (valid) => {
        if (valid) {
          let res = await this.$http.taskTagSave({...ruleForm});
          if (res) {
            this.$message.success("操作成功！");
            this.getTableList();
            this.addVisible = false;
            this.$refs.ruleForm.resetFields();
          }
        }
      });
    },
    formatColorTen(color){
      const hexColorWithHash = color
      const hexColor = hexColorWithHash.slice(1); 
      const decimalColor = parseInt('FF'+hexColor, 16);
      return decimalColor
    },
    formatColorSixteen(color){
      if(!color) return;
      const hex = '#' + color.toString(16).slice(2);
      return hex
    },
    async getTableList(val) {
      if(val) {
        this.searchForm.pageNo = 1
      }
      let res = await this.$http.taskTagList({...this.searchForm});
      if (res) {
        this.tableData = res.result.data;
        this.totalNum = res.result.totalCount;
      }
    },
    delFunc(row) {
      this.$confirm(`确定要删除么？`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          let res = await this.$http.taskTagDelete([row.id])
          if (res) {
            this.$message.success("操作成功！");
            this.getTableList();
          }
        })
        .catch(() => {});
    },
  },
};
</script>

<style scoped>
.icon{
  width: 60px;
}
</style>
